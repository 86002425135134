import { z } from 'zod'
import type { ApiType } from '~/api/types'

export type ApiErrorWithCode = z.infer<typeof apiErrorWithCodeSchema>
export const apiErrorWithCodeSchema = z.object({
  code: z.string(),
  detail: z.string(),
})

export type ApiError = z.infer<typeof apiErrorSchema>
export const apiErrorSchema = z.object({
  detail: z.string(),
})

const apiValidationErrorSchema = z.object({
  ctx: z.unknown().nullish(),
  loc: z.array(z.union([z.string(), z.number()])),
  msg: z.string(),
  type: z.string(),
}) satisfies z.ZodType<ApiType<'ValidationError'>>

export type ApiValidationErrors = z.infer<typeof apiValidationErrorsSchema>
export const apiValidationErrorsSchema = z.object({
  detail: z.array(apiValidationErrorSchema),
}) satisfies z.ZodType<ApiType<'HTTPValidationError'>>
