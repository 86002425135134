import { $channelId } from '@channel/channel.model'
import type { RemoteOperationParams } from '@farfetched/core'
import { createMutation } from '@farfetched/core'
import { invoke } from '@withease/factories'
import { attach, restore, sample } from 'effector'
import { createAction } from 'effector-action'
import { delay } from 'patronum'
import type { Except } from 'type-fest'
import { z } from 'zod'
import { createInviteLinkBaseFx, type LinkServiceComponents } from '~/api/links'
import { copyToClipboardFx } from '~/features/copy_to_clipboard'
import { extractApiErrorMessageFx } from '~/features/extract_api_error_message'
import { showSuccessToastFx } from '~/features/show_toast'
import { createDisclosureAtom } from '~/shared/lib/factories/create-disclosure'
import {
  createFormAtom,
  createFormField,
  createRecordFormField,
} from '~/shared/lib/factories/form'
import { sharedInviteLinksFilterModel } from '../../analytics_filters/invite_links_filter/model'
import { forceRefreshTags } from '../../tags/tags.model'
import { dialogDisclosure } from '../dialog.model'
import { forceAllInviteLinksQuery } from '../links.model'

export const createInviteLinkTableDialogDisclosure =
  invoke(createDisclosureAtom)
export const createInviteLinkToolbarDialogDisclosure =
  invoke(createDisclosureAtom)

type CreateInviteLinkInputs = Except<
  LinkServiceComponents<'InviteLinkCreateDTO'>,
  'channel_id' | 'budget' | 'tags'
> & { tags: Record<string, string | null | undefined> }

export const nameField = invoke(() =>
  createFormField<string>({ initialValue: '' }),
)

export const createJoinRequestField = invoke(() =>
  createFormField<boolean>({
    initialValue: false,
  }),
)

export const expireDateField = invoke(() =>
  createFormField<string | null | undefined>(),
)

export const memberLimitField = invoke(() =>
  createFormField<number | null | undefined>(),
)

export const tagsKvField = invoke(() =>
  createRecordFormField<Record<string, string | null | undefined>>({
    initialValue: {},
  }),
)

export const form = invoke(() =>
  createFormAtom({
    schema: z.object({
      name: z.string().trim().min(1),
      creates_join_request: z.boolean(),
      expire_date: z.string().optional().nullable(),
      member_limit: z.number().optional().nullable(),
      tags: z.record(z.string(), z.string().optional().nullable()),
    }) satisfies z.ZodType<CreateInviteLinkInputs>,
    fields: {
      name: nameField,
      creates_join_request: createJoinRequestField,
      expire_date: expireDateField,
      member_limit: memberLimitField,
      tags: tagsKvField,
    },
  }),
)

export const createInviteLinkMutation = createMutation({
  effect: createInviteLinkBaseFx,
})

sample({
  clock: [
    createInviteLinkTableDialogDisclosure.closed,
    createInviteLinkToolbarDialogDisclosure.closed,
    dialogDisclosure.closed,
  ],
  target: form.clear,
})

sample({
  clock: form.validated,
  source: { channel_id: $channelId },
  fn: ({ channel_id }, { tags, ...inputs }) =>
    ({
      body: {
        channel_id: channel_id!,
        tags: Object.values(tags).filter(Boolean),
        budget: 0,
        ...inputs,
      },
    }) satisfies RemoteOperationParams<typeof createInviteLinkMutation>,
  target: createInviteLinkMutation.start,
})

sample({
  clock: copyToClipboardFx.done,
  fn: () => 'Ссылка успешно создана и скопирована в буфер обмена',
  target: showSuccessToastFx,
})

const getErrorMessageFx = attach({ effect: extractApiErrorMessageFx })

sample({
  clock: createInviteLinkMutation.finished.failure,
  fn: ({ error }) => error,
  target: getErrorMessageFx,
})

sample({
  clock: getErrorMessageFx.doneData,
  target: form.setError,
})

export const $createdInviteLink = restore(
  createInviteLinkMutation.finished.success.map(({ result }) => result),
  null,
)

delay({
  source: sample({
    clock: $createdInviteLink,
    source: { channelId: $channelId, dialogIsOpen: dialogDisclosure.$isOpen },
    filter: ({ channelId, dialogIsOpen }, newInviteLink) =>
      !!newInviteLink && newInviteLink.channel_id === channelId && dialogIsOpen,
  }),
  timeout: 5_000,
  target: $createdInviteLink.reinit,
})

sample({
  clock: [$channelId, dialogDisclosure.closed],
  target: $createdInviteLink.reinit,
})

createAction(createInviteLinkMutation.finished.success, {
  target: {
    closeTableDialog: createInviteLinkTableDialogDisclosure.close,
    closeToolbarDialog: createInviteLinkToolbarDialogDisclosure.close,
    forceRefreshTags,
    copyToClipboardFx,
    clearForm: form.clear,
    forceRefreshLinks: forceAllInviteLinksQuery.refresh,
    clearModel: sharedInviteLinksFilterModel.clearModel,
  },
  source: {
    tableDialogIsOpen: createInviteLinkTableDialogDisclosure.$isOpen,
    toolbarDialogIsOpen: createInviteLinkToolbarDialogDisclosure.$isOpen,
  },
  fn: (target, source, { result }) => {
    if (source.tableDialogIsOpen) {
      target.closeTableDialog()
    }
    if (source.toolbarDialogIsOpen) {
      target.closeToolbarDialog()
    }
    target.copyToClipboardFx(result.invite_link)
    target.clearForm()
    target.forceRefreshTags()
    target.forceRefreshLinks({})
    target.clearModel()
  },
})
