import {
  apiErrorSchema,
  apiErrorWithCodeSchema,
  apiValidationErrorsSchema,
  type ApiError,
  type ApiErrorWithCode,
  type ApiValidationErrors,
} from './schema'

export function isApiErrorWithCode(err: unknown): err is ApiErrorWithCode {
  return apiErrorWithCodeSchema.safeParse(err).success
}

export function isApiValidationErrors(
  err: unknown,
): err is ApiValidationErrors {
  return apiValidationErrorsSchema.safeParse(err).success
}

export function isApiError(err: unknown): err is ApiError {
  return apiErrorSchema.safeParse(err).success
}
