import { $channelId } from '@channel/channel.model'
import type { RemoteOperationParams } from '@farfetched/core'
import { concurrency, createQuery } from '@farfetched/core'
import { invoke } from '@withease/factories'
import {
  attach,
  combine,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import { produce } from 'immer'
import { debounce, spread } from 'patronum'
import { getAccountsBaseFx } from '~/api/auth.effects'
import { getInviteLinksBaseFx, type LinkServiceComponents } from '~/api/links'
import { createStateAtom } from '~/shared/lib/factories/create-state'
import { dialogDisclosure } from './dialog.model'
import type { InviteLinksOrder, Tag, TagsFiltering, TagType } from './types'

const DEFAULT_LIMIT = 100

const pageAtom = invoke(() => createStateAtom({ defaultState: 1 }))

export const nameSearchAtom = invoke(() =>
  createStateAtom({ defaultState: '' }),
)

export const tagsIdsKvAtom = invoke(() =>
  createStateAtom<Record<TagType['id'], Tag['id'][]>>({
    defaultState: {},
  }),
)

export const selectedEmptyTagTypesIdsAtom = invoke(() =>
  createStateAtom<TagType['id'][]>({ defaultState: [] }),
)

export const tagsSelected = createEvent<{
  tagTypeId: TagType['id']
  tagsIds: Tag['id'][]
}>()

export const emptyTagTypeIdSelected = createEvent<TagType['id']>()

export const tagsReset = createEvent<{
  tagTypeId: TagType['id']
}>()

sample({
  clock: tagsSelected,
  source: { kv: tagsIdsKvAtom.$value },
  fn: ({ kv }, { tagTypeId, tagsIds }) => {
    return produce(kv, draft => {
      if (tagsIds.length === 0) {
        delete draft[tagTypeId]
      } else {
        draft[tagTypeId] = tagsIds
      }
    })
  },
  target: tagsIdsKvAtom.$value,
})

sample({
  clock: emptyTagTypeIdSelected,
  source: { arr: selectedEmptyTagTypesIdsAtom.$value },
  fn: ({ arr }, tagTypeId) => {
    return arr.includes(tagTypeId)
      ? arr.filter(currentTagTypeId => currentTagTypeId !== tagTypeId)
      : [...arr, tagTypeId]
  },
  target: selectedEmptyTagTypesIdsAtom.$value,
})

sample({
  clock: tagsReset,
  source: {
    kv: tagsIdsKvAtom.$value,
    arr: selectedEmptyTagTypesIdsAtom.$value,
  },
  fn: ({ kv, arr }, { tagTypeId }) => {
    return {
      tagsIds: produce(kv, draft => {
        delete draft[tagTypeId]
      }),
      emptyTagsTypesIds: arr.filter(
        currentTagTypeId => currentTagTypeId !== tagTypeId,
      ),
    }
  },
  target: spread({
    tagsIds: tagsIdsKvAtom.$value,
    emptyTagsTypesIds: selectedEmptyTagTypesIdsAtom.$value,
  }),
})

export const $serverOrder = createStore<InviteLinksOrder>('-created_at')
export const serverOrderToggled =
  createEvent<Extract<InviteLinksOrder, 'created_at' | 'name'>>()

sample({
  clock: serverOrderToggled,
  source: $serverOrder,
  fn: (currentOrder, orderKey) => {
    return (
      currentOrder === orderKey ? `-${orderKey}` : orderKey
    ) satisfies InviteLinksOrder
  },
  target: $serverOrder,
})

const resetAllFilter = createEvent()
sample({
  clock: resetAllFilter,
  target: [
    nameSearchAtom.reset,
    tagsIdsKvAtom.reset,
    selectedEmptyTagTypesIdsAtom.reset,
  ] as const,
})

const resetAllSort = createEvent()
sample({
  clock: resetAllSort,
  target: [$serverOrder.reinit] as const,
})

export const resetFilteringAndSorting = createEvent()
sample({
  clock: resetFilteringAndSorting,
  target: [resetAllFilter, resetAllSort] as const,
})

export const $hasFiltersOrSorting = combine(
  [
    nameSearchAtom.$value,
    tagsIdsKvAtom.$value,
    selectedEmptyTagTypesIdsAtom.$value,
    $serverOrder,
  ],
  ([name, tags, tagsTypes, order]) => {
    return (
      name !== nameSearchAtom.$value.defaultState ||
      Object.values(tags).length !== 0 ||
      tagsTypes.length !== 0 ||
      order !== $serverOrder.defaultState
    )
  },
)

export const inviteLinksQuery = createQuery({
  effect: getInviteLinksBaseFx,
})

concurrency(inviteLinksQuery, {
  strategy: 'TAKE_LATEST',
})

export const $inviteLinks = createStore<
  LinkServiceComponents<'InviteLinkResponseDTO'>[]
>([])

const inviteLinksAccountsQuery = createQuery({
  effect: getAccountsBaseFx,
  mapData: ({ result }) =>
    result.reduce(
      (record, account) => {
        record[account.id] = account.name ?? account.email ?? ''
        return record
      },
      {} as Record<string, string>,
    ),
  initialData: {},
})

export const $accountsNamesKv = inviteLinksAccountsQuery.$data

concurrency(inviteLinksAccountsQuery, {
  strategy: 'TAKE_LATEST',
})

export const $inviteLinksIds = createStore<
  LinkServiceComponents<'InviteLinkResponseDTO'>['id'][]
>([])

export const $loadedInviteLinksCount = combine(
  $inviteLinks,
  data => data.length,
)

export const $totalILinksCount = restore(
  inviteLinksQuery.finished.success.map(({ result }) => result.total),
  0,
)

const $hasMore = combine(
  [$loadedInviteLinksCount, $totalILinksCount],
  ([loaded, total]) => {
    return loaded < total
  },
)

const loadMore = createEvent()

export const linksContainerRangeChanged = createEvent<[number, number]>()

sample({
  clock: linksContainerRangeChanged,
  source: {
    hasMore: $hasMore,
    queryStatus: inviteLinksQuery.$status,
    inviteLinksIds: $inviteLinksIds,
  },
  filter: ({ hasMore, queryStatus, inviteLinksIds }, [_, endIndex]) => {
    if (queryStatus === 'initial') return false
    if (queryStatus === 'pending') return false

    const result = hasMore && endIndex + 10 >= inviteLinksIds.length
    return result
  },
  target: loadMore,
})

sample({
  clock: loadMore,
  source: pageAtom.$value,
  fn: currentPage => currentPage + 1,
  target: pageAtom.set,
})

sample({
  clock: inviteLinksQuery.finished.success,
  source: $inviteLinks,
  fn: (currentLinks, { result: { items } }) => [...currentLinks, ...items],
  target: $inviteLinks,
})

sample({
  clock: inviteLinksQuery.finished.success,
  source: $inviteLinksIds,
  fn: (currentLinksIds, { result: { items } }) => [
    ...currentLinksIds,
    ...items.map(({ id }) => id),
  ],
  target: $inviteLinksIds,
})

// При смене фильтра/лимита/сортировки сбрасываем текущие ссылки
sample({
  clock: [
    nameSearchAtom.$value,
    tagsIdsKvAtom.$value,
    $serverOrder,
    selectedEmptyTagTypesIdsAtom.$value,
  ],
  target: [
    pageAtom.reset,
    inviteLinksQuery.reset,
    $inviteLinks.reinit,
    $inviteLinksIds.reinit,
  ] as const,
})

const dialogOpened = sample({
  clock: dialogDisclosure.opened,
  source: inviteLinksQuery.$status,
  filter: status => status === 'initial',
})

sample({
  clock: [
    dialogOpened,
    pageAtom.$value,
    debounce(nameSearchAtom.$value, 1500),
    $serverOrder,
    tagsIdsKvAtom.$value,
    selectedEmptyTagTypesIdsAtom.$value,
  ],
  source: {
    channel_id: $channelId,
    page: pageAtom.$value,
    name: nameSearchAtom.$value,
    order: $serverOrder,
    tagsKv: tagsIdsKvAtom.$value,
    emptyTagsTypesIds: selectedEmptyTagTypesIdsAtom.$value,
  },
  filter: dialogDisclosure.$isOpen,
  fn: ({ channel_id, page, name, order, tagsKv, emptyTagsTypesIds }) => {
    const tagsBody: TagsFiltering[] = []

    for (const [tagTypeId, tagsIds] of Object.entries(tagsKv)) {
      tagsBody.push({
        tag_type_id: tagTypeId,
        tags: tagsIds,
        without_type: false,
      })
    }

    for (const emptiedTagTypeId of emptyTagsTypesIds) {
      const currentItem = tagsBody.find(
        ({ tag_type_id }) => tag_type_id === emptiedTagTypeId,
      )

      if (currentItem) {
        currentItem.without_type = true
      } else {
        tagsBody.push({
          tag_type_id: emptiedTagTypeId,
          without_type: true,
          tags: [],
        })
      }
    }

    return {
      params: {
        query: {
          channel_id: channel_id!,
          limit: DEFAULT_LIMIT,
          page,
          name,
          ordered_fields: [order],
        },
      },
      body: { tags: tagsBody },
    } satisfies RemoteOperationParams<typeof inviteLinksQuery>
  },
  target: inviteLinksQuery.refresh,
})

const $inviteLinksAccountsIds = combine([$inviteLinks], ([links]) => {
  return links.reduce((ids, link) => {
    if (typeof link.creator_id === 'number') {
      ids.push(link.creator_id)
    }
    return ids
  }, [] as number[])
})

sample({
  clock: $inviteLinksAccountsIds,
  filter: ids => ids.length > 0,
  fn: ids =>
    ({
      params: {
        query: {
          accounts_ids: ids,
        },
      },
    }) satisfies RemoteOperationParams<typeof inviteLinksAccountsQuery>,
  target: inviteLinksAccountsQuery.refresh,
})

export const forceAllInviteLinksQuery = createQuery({
  effect: attach({
    effect: getInviteLinksBaseFx,
    source: {
      channel_id: $channelId,
      name: nameSearchAtom.$value,
      order: $serverOrder,
      tagsKv: tagsIdsKvAtom.$value,
      emptyTagsTypesIds: selectedEmptyTagTypesIdsAtom.$value,
    },
    mapParams: (_, { channel_id, emptyTagsTypesIds, name, order, tagsKv }) => {
      const tagsBody: TagsFiltering[] = []

      for (const [tagTypeId, tagsIds] of Object.entries(tagsKv)) {
        tagsBody.push({
          tag_type_id: tagTypeId,
          tags: tagsIds,
          without_type: false,
        })
      }

      for (const emptiedTagTypeId of emptyTagsTypesIds) {
        const currentItem = tagsBody.find(
          ({ tag_type_id }) => tag_type_id === emptiedTagTypeId,
        )

        if (currentItem) {
          currentItem.without_type = true
        } else {
          tagsBody.push({
            tag_type_id: emptiedTagTypeId,
            without_type: true,
            tags: [],
          })
        }
      }

      return {
        params: {
          query: {
            channel_id: channel_id!,
            limit: -1,
            name,
            ordered_fields: [order],
          },
        },
        body: { tags: tagsBody },
      }
    },
  }),
})

sample({
  clock: forceAllInviteLinksQuery.finished.success,
  fn: ({ result }) => {
    return {
      inviteLinks: result.items,
      inviteLinksIds: result.items.map(({ id }) => id),
      totalILinksCount: result.total,
    }
  },
  target: spread({
    inviteLinks: $inviteLinks,
    inviteLinksIds: $inviteLinksIds,
    totalILinksCount: $totalILinksCount,
  }),
})

sample({
  clock: $channelId,
  target: [
    inviteLinksQuery.reset,
    $inviteLinks.reinit,
    $inviteLinksIds.reinit,
    nameSearchAtom.reset,
    $serverOrder.reinit,
    pageAtom.reset,
    tagsIdsKvAtom.reset,
    selectedEmptyTagTypesIdsAtom.reset,
    $totalILinksCount.reinit,
    inviteLinksAccountsQuery.reset,
    forceAllInviteLinksQuery.reset,
  ] as const,
})

export const $isInviteLinksFetching = combine(
  [inviteLinksQuery.$pending, forceAllInviteLinksQuery.$pending],
  stores => stores.some(Boolean),
)
