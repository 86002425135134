import {
  isApiError,
  isApiErrorWithCode,
  isApiValidationErrors,
} from '~/shared/api/services/auth/guard'

const DEFAULT_API_ERROR_MESSAGE = 'Неизвестная ошибка'

export async function getApiErrorMessage(err: unknown): Promise<string> {
  if (err instanceof Error) {
    return err.message
  } else if (err instanceof Response) {
    const errorResponse = err.clone()

    const error = await errorResponse
      .text()
      .then(errorText => JSON.parse(errorText))
      .catch(err =>
        err instanceof Error ? err.message : DEFAULT_API_ERROR_MESSAGE,
      )

    if (error instanceof Error) {
      return error.message
    } else if (isApiErrorWithCode(error)) {
      return error.detail || DEFAULT_API_ERROR_MESSAGE
    } else if (isApiError(error)) {
      return error.detail || DEFAULT_API_ERROR_MESSAGE
    } else if (isApiValidationErrors(error)) {
      return (
        error.detail?.map(({ msg }) => msg).join(', ') ??
        DEFAULT_API_ERROR_MESSAGE
      )
    }
  } else if (typeof err === 'object' && err !== null && 'detail' in err) {
    if (
      Array.isArray(err.detail) &&
      err.detail.some(
        errDetail =>
          typeof errDetail === 'object' &&
          errDetail !== null &&
          'msg' in errDetail,
      )
    ) {
      return err.detail.map(err => (err as { msg: string }).msg).join(', ')
    } else {
      return err.detail as string
    }
  }

  return DEFAULT_API_ERROR_MESSAGE
}
