import { createEffect } from 'effector'

export async function copyToClipboard(value: string) {
  try {
    await navigator.clipboard.writeText(value)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

export const copyToClipboardFx = createEffect(copyToClipboard)
